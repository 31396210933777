import React, { Component } from 'react';
import styles from './style';
import { withStyles } from '@material-ui/core/styles';
import * as apiUserService from '../../services/confirm';
import { MURecaptcha } from 'basic-components';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MUTextValidator from '../ui/MUTextValidator';
import Button from '../ui/Button';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as Actions from '../../redux/actions';
import _ from 'lodash';
import { RECAPTCHA_SITEKEY } from '../../config/environment.js';
import BasicPageComponent from '../BasicPageComponent';
import PropTypes from 'prop-types';
import MUTypography from '../ui/MUTypography';

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isRecaptchaVerified: false,
      email: '',
      name: '',
      subject: '',
      message: '',
      recaptchaResponse: '',
      captchaRendered: false
    };

    this.emptyState = this.state;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.setState({ captchaRendered: true });
    }, 500);
  }

  handleSubmit = () => {
    const { name, email, subject, message, recaptchaResponse } = this.state;

    apiUserService.saveContact(name, email, subject, message, recaptchaResponse)
      .then((responseJson) => {
        console.log(responseJson);
        this.props.actions.setNotification('Your message has been received', 'success');
        window.grecaptcha.reset();
        this.setState(this.emptyState);
        setTimeout(() => {
          this.setState({ captchaRendered: true });
        }, 500);
      })
      .catch((error) => {
        if (_.isNil(error.response)) {
          if (!_.isNil(error.message)) {
            this.props.actions.setNotification(error.message, 'error');
          } else {
            this.props.actions.setNotification('System error occured', 'error');
          }
          return;
        }

        error.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            this.props.actions.setNotification(r.header.message, 'error');
          });
      })
      .finally(() => {
        this.setState({ recaptchaResponse: '' });
      });
  }

  handleChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  verifyCallback = (response) => {
    this.setState({ recaptchaResponse: response });
  };

  render() {
    const { classes } = this.props;
    const { name, email, subject, message } = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <BasicPageComponent
          title='Contact Us'
          description='You can contact us by filling out the form below.'
          children={
            <ValidatorForm
              onSubmit={this.handleSubmit}
              className={classes.form}
            >
              <div className={classes.screenContainer}>
                <div className={classes.screenSubContainer}>
                  <div className={classes.textField}>
                    <div style={{ width: '50%' }}>
                      <MUTypography className={classes.typography}>Your Name</MUTypography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <MUTextValidator
                        id="name"
                        onChange={(e) => this.handleChange(e, 'name')}
                        name="name"
                        value={name}
                        validators={['required', 'maxStringLength:30']}
                        errorMessages={['Required', 'The maximum allowed length is 30']}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.screenSubContainer}>
                  <div className={classes.textField}>
                    <div style={{ width: '50%' }}>
                      <MUTypography className={classes.typography}>Your Email</MUTypography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <MUTextValidator
                        id="mailaddress"
                        onChange={(e) => this.handleChange(e, 'email')}
                        name="email"
                        value={email}
                        validators={['required', 'isEmail', 'maxStringLength:50']}
                        errorMessages={['Required', 'Please enter a valid email address', 'The maximum allowed length is 50']}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.screenSubContainer}>
                  <div className={classes.textField}>
                    <div style={{ width: '50%' }}>
                      <MUTypography className={classes.typography}>Subject</MUTypography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <MUTextValidator
                        id="subject"
                        onChange={(e) => this.handleChange(e, 'subject')}
                        name="subject"
                        value={subject}
                        validators={['required', 'maxStringLength:100']}
                        errorMessages={['Required', 'The maximum allowed length is 100']}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.screenSubContainer}>
                  <div className={classes.textField}>
                    <div style={{ width: '50%' }}>
                      <MUTypography className={classes.typography}>Your Message</MUTypography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <MUTextValidator
                        required
                        name="message"
                        id="message"
                        multiline={true}
                        rows="5"
                        classes={{ input: classes.input }}
                        value={message}
                        onChange={(e) => this.handleChange(e, 'message')}
                        margin="normal"
                        validators={['required', 'maxStringLength:255']}
                        errorMessages={['Required', 'The maximum allowed length is 255']}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.screenSubContainer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                  <div className={classes.textField} style={{ textAlign: 'center', marginLeft: '50px' }}>
                    {this.state.captchaRendered ?
                      <MURecaptcha
                        verifyCallback={(response) => this.verifyCallback(response)}
                        sitekey={RECAPTCHA_SITEKEY}
                      /> : null
                    }
                  </div>
                </div>

                <div className={classNames(classes.center, classes.btnSubmit)}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className='oulinedPrimary'
                    type="submit"
                    text="SEND" />
                </div>  
              </div>
            </ValidatorForm>
          }
        />
      </div>
    );
  }
}

Contact.propTypes = {
  login: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Contact);
