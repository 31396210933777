import * as actionTypes from '../../constants/actionTypes';

export default function productGroups(state = '', action) {
  switch (action.type) {
    case actionTypes.ADD_PRODUCT_GROUPS:
      return action.groups;
    default:
      return state;
  }

}
