import { combineReducers } from 'redux';
import mainContent from './mainContent';
import infoContent from './infoContent';
import swaggerContent from './swaggerContent';
import productList from './productList';
import services from './servicesList';
import groups from './groupsList';

const productsRootReducer = combineReducers({
  contentType: mainContent,
  infoContent,
  swaggerContent,
  productList,
  services,
  groups
});

export default productsRootReducer;
