const styles = (theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Üstten sabit başlasın
        alignItems: "center",
        minHeight: "auto",
        backgroundColor: "#f9f9f9",
        padding: "auto",
        overflow: "hidden",
        height: "auto",  // Ekranın tamamını kaplasın
    },
    card: {
        width: "106%",
        maxWidth: "1400px",
        padding: "50px",
        backgroundColor: "#fff",
        marginTop: "-40px",
        minHeight: "400px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        border: "none", // Tablo çerçevesi
        boxShadow: "none", // GÖLGEYİ KALDIRIR
        borderRadius: "0px", // Tüm kenarları düz yapar
        paddingBottom: "20px", 
        marginBottom: "0px"
    },
    title: {
        fontSize: "32px",
        fontWeight: "bold",
        textAlign: "center",
        color: "#222",
        marginBottom: "10px",
        marginTop: "20px", // Başlık yukarı çekildi
    },
    description: {
        fontSize: "16px",
        textAlign: "center",
        color: "#555",
        marginBottom: "30px",
        marginTop: "0px", // Açıklama en üstte sabitlendi
    },
    stepper: {
        padding: "10px 0",

    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        flex: 1,
        flexGrow: 1, // Sayfanın kalan alanını doldur
    },
    textField: {
        width: "100%",
        "& label": {
            fontSize: "14px",
        },
        "& input": {
            fontSize: "16px",
            padding: "14px",
        },
    },
    radioGroup: {
        display: "flex",
        flexDirection: "column",
        gap: "18px", // Radio butonları arasındaki boşluğu artırdım
        padding: "10px 0",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between", // Butonları sağa ve sola yasla
        width: "100%",
        maxWidth: "500px", // Butonların genişliği belirli bir alan içinde sabit kalır
        margin: "20px auto 0", // Üstten boşluk ver, ortala
    },
    
    
    
    button: {
        padding: "14px 24px",
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "8px",
        "&.MuiButton-contained": {
            backgroundColor: "#007bff",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#0056b3",
            },
        },
        "&.MuiButton-outlined": {
            borderColor: "#007bff",
            color: "#007bff",
            "&:hover": {
                borderColor: "#0056b3",
                color: "#0056b3",
            },
        },
    },
    fileUpload: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px",
        border: "2px dashed #007bff",
        borderRadius: "8px",
        cursor: "pointer",
        backgroundColor: "#f0f8ff",
        "&:hover": {
            backgroundColor: "#e0f0ff",
        },
    },
    summaryBox: {
        backgroundColor: "#f8f8f8",
        padding: "24px",
        borderRadius: "8px",
        border: "1px solid #ddd",
    },
    "@media (max-width: 768px)": {
        card: {
            width: "100%",
            padding: "30px",
        },
        title: {
            fontSize: "24px",
        },
        textField: {
            "& input": {
                fontSize: "14px",
                padding: "12px",
            },
        },
        sidebar: {
            marginRight: "0px",
        },
    },
});

export default styles;
