import _ from 'lodash';
import { createSelector } from 'reselect';

/** ******** HELPER ******* */
function groupBy(myArray, key) {
  return _.mapValues(_.groupBy(myArray, key),
    list => list.map(myArray => _.omit(myArray, key)));
}

/** ******** SELECTORS ******* */
// Redux'taki ürünleri getir
export const getProducts = (state) => {
  return (state.products && !_.isEmpty(state.products.productList)) 
    ? state.products.productList 
    : [];
};

// Redux'taki servisleri `swaggerUrl`'e göre gruplandır
export const getProductServices = (state) => (

  groupBy(state.products.services, 'swaggerUrl') || []
);

// Redux'taki ürün gruplarını `productId`'ye göre gruplandır
export const getProductGroups = (state) => {
  const productGroupsData = (state.products && !_.isEmpty(state.products.groups)) 
    ? state.products.groups 
    : [];
  return groupBy(productGroupsData, 'productId') || [];
  
};


/** ******** COMBINE FUNCTION ******* */
// Ürünlere, ürün gruplarını ve onların içerdiği servisleri bağlayan selector
export const combineProductsWithGroupsAndServices = createSelector(
  getProducts,
  getProductGroups,
  getProductServices,
  (products, servicesGroups, services) => {
    console.log("Products:", products);
    console.log("Product Groups:", servicesGroups);
    console.log("Product Services:", services);
    return _.map(products, product => {
      // Ürün grubunu `productId` ile eşleştir
      const groups = servicesGroups[product.id] || [];

      

      // Her grup için servisleri `swaggerUrl` üzerinden bağla
      _.each(groups, group => {
        group.services = services[group.swaggerUrl] || [];
      });

      // Ürün nesnesine `groups` ekle
      return {
        ...product,
        servicesGroups: groups
      };
    });
  }
);
