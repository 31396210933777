import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  LOGOUT_USER,
  TOKEN_REFRESH,
  TOKEN_REFRESH_SUCCESS,
  ADD_PRODUCTS,
  GET_PRODUCTS,
  ADD_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS,
  ADD_PRODUCT_SERVICES,
  GET_PRODUCT_SERVICES,
  SET_USER_DISABLED,
  INITIALIZE } from './actionTypes';

export const DocsMainContentType = {
  INFO: 'info',
  SWAGGER: 'swagger'
};

export {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT,
  LOGOUT_USER,
  TOKEN_REFRESH,
  TOKEN_REFRESH_SUCCESS,
  ADD_PRODUCTS,
  ADD_PRODUCT_SERVICES,
  ADD_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS,
  GET_PRODUCTS,
  GET_PRODUCT_SERVICES,
  SET_USER_DISABLED,
  INITIALIZE
};
