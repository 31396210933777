import { takeLatest, put, call, fork, select, all } from 'redux-saga/effects';
import * as constants from '../../redux/constants/actionTypes';
import * as selectors from '../../redux/selectors';
import * as actions from '../../redux/actions';
import * as apiDashBoardService from '../../services/apidashboardservice';

import _ from 'lodash';

/** ****************************************************************************/
/** ***************************** WORKERS *************************************/
/** ****************************************************************************/

export function * loadProducts() {
  var products = yield select(selectors.getProducts);

  if (_.isNil(products) || _.isEmpty(products)) {
    try {
      products = yield call(apiDashBoardService.getProducts);
      yield put(actions.addProducts(products.data));
    } catch (error) {
      yield put(actions.addProducts([]));
    }
  }
}

export function * loadProductsGroups() {
  var productsGroups = yield select(selectors.getProductGroups);

  if (_.isNil(productsGroups) || _.isEmpty(productsGroups)) {
    try {
      productsGroups = yield call(apiDashBoardService.getProductGroups);
      yield put(actions.addProductGroups(productsGroups.data));
    } catch (error) {
      yield put(actions.addProductGroups([]));
    }
  }
}

export function * loadProductServices() {
  var productServices = yield select(selectors.getProductServices);

  if (_.isNil(productServices) || _.isEmpty(productServices)) {
    try {
      productServices = yield call(apiDashBoardService.getProductServices);
      yield put(actions.addProductServices(productServices.data));
    } catch (error) {
      yield put(actions.addProductServices([]));
    }
  }
}

/** ****************************************************************************/
/** ***************************** WATCHERS *************************************/
/** ****************************************************************************/

// load products if not cached then fetches data
export function * watchGetProducts() {
  yield takeLatest(constants.GET_PRODUCTS, loadProducts);
}

export function * watchGetProductsGroups() {
  yield takeLatest(constants.GET_PRODUCT_GROUPS, loadProductsGroups);
}

// load product services if not cached then fetches data
export function * watchGetProductServices() {
  yield takeLatest(constants.GET_PRODUCT_SERVICES, loadProductServices);
}

/** ***************************  PRODUCT ROOT SAGA *********************************/

export function * productsRootSaga() {
  yield all([
    fork(watchGetProducts),
    fork(watchGetProductsGroups),
    fork(watchGetProductServices)

  ]);
}

