import React, { Component } from 'react';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import * as apiUserService from '../../services/confirm';
import * as constants from './registerConstants';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MURecaptcha } from 'basic-components';
import MUTextValidator from '../ui/MUTextValidator';
import Button from '../ui/Button';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import {RECAPTCHA_SITEKEY} from '../../config/environment.js';
import _ from 'lodash';
import { setNotification } from '../../redux/actions';
import { store } from '../../../src';
import BasicPageComponent from '../BasicPageComponent';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import NotificationPage from '../NotificationPage';
import Header from '../Header';
class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      country: '-1',
      firstname: '',
      lastname: '',
      emailaddress: '',
      phonenumber: '',
      password: '',
      confirmpassword: '',
      recaptchaResponse: '',
      passwordIsMasked: true,
      confirmPasswordIsMasked: true,
      beforeRegister: true,
      onServiceCall: false,
      captchaRendered: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    // Minimum uzunluk kontrolü
    ValidatorForm.addValidationRule('minLength', (value) => {
      return value.length >= 8;
    });
  
    // En az bir büyük harf, bir küçük harf ve bir rakam içermeli
    ValidatorForm.addValidationRule('strongPassword', (value) => {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
      return regex.test(value);
    });
  
    // Yaygın şifreleri engelle
    ValidatorForm.addValidationRule('notCommonPassword', (value) => {
      const commonPasswords = [
        'galatasaray1905.',
        'fenerbahce1907.',
        'besiktas1903.',
        'trabzonspor1967.',
        'qwerty123.',
        'asdfgh123@',
        'admin1234.',
        'p@ssw0rd.',
        'ankara06.',
        'istanbul34.',
        'sifre1234!',
        'merhaba01@',
        'kaptan1905$',
        'sevgi2024!',
        'telefon01*',
        'parola2023!',
        'evdekal2020#',
        'turkiye1923!',
        'sonbahar2022@',
        'ilkbahar2023!'
      ];
      return !commonPasswords.includes(value.toLowerCase());
    });
  
    // Şifre eşleşme kontrolü
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      return value === this.state.password;
    });

    setTimeout(()=> {
      this.setState({captchaRendered: true});
    }, 500);
  }

  handleChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  handleSubmit = () => {
    window.grecaptcha.reset();
    this.setState({onServiceCall: true});
    this.register();
  }

  verifyCallback(response) {
    this.setState({recaptchaResponse: response});
  };

  togglePasswordMask = (e, passwordType) => {
    e.preventDefault();

    if (passwordType === 'password') {
      this.setState(prevState => ({
        passwordIsMasked: !prevState.passwordIsMasked
      }));
    } else if (passwordType === 'confirmpassword') {
      this.setState(prevState => ({
        confirmPasswordIsMasked: !prevState.confirmPasswordIsMasked
      }));
    }
  };

  register = () => {
    const { firstname, lastname, emailaddress, phonenumber, password, country, recaptchaResponse } = this.state;

    apiUserService.register(firstname, lastname, emailaddress, phonenumber, password, country, recaptchaResponse, 15000)
      .then((responseJson) => {
        this.setState({ beforeRegister: false });
        console.log(responseJson);
      })
      .catch((exception) => {
        if (_.isNil(exception.response)) {
          if (!_.isNil(exception.message)) {
            store.dispatch(setNotification(exception.message, 'error'));
          } else {
            store.dispatch(setNotification('System error occured', 'error'));
          }
          return;
        }
        exception.response.json()
          .then(r => {
            console.log('ERROR_contactus: ' + JSON.stringify(r.header));
            store.dispatch(setNotification(r.header.message, 'error'));
          });
      })
      .finally(()=>{
        this.setState({ onServiceCall: false,
          recaptchaResponse: '' });
      });
  }

  render() {
    const { classes } = this.props;
    const { firstname, lastname, emailaddress, password,
      passwordIsMasked, confirmPasswordIsMasked, confirmpassword, country, beforeRegister, onServiceCall} = this.state;

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        {beforeRegister ?
          <BasicPageComponent
            title='Register'
            description='To discover the world of Albaraka API, you need to complete registration steps.'
            children = {
              <div>
                <div>
                  {onServiceCall ?
                    <div className={classes.root}>
                      <LinearProgress classes={{
                        colorPrimary: classes.colorPrimaryFirst,
                        barColorPrimary: classes.barColorPrimaryFirst }} />
                      <br />
                      <LinearProgress
                        classes={{ colorPrimary: classes.colorPrimarySecond, barColorPrimary: classes.barColorPrimarySecond }}
                      />
                    </div> :
                    null}
                </div>
                <div>
                  <ValidatorForm
                    onSubmit={this.handleSubmit}
                    className= {classes.form}
                  >
                    <div className = {classes.textField}>
                      < MUTextValidator
                        id="firstname"
                        onChange={(e) => this.handleChange(e, 'firstname')}
                        name="firstname"
                        value={firstname}
                        placeholder = 'YOUR NAME'
                        validators={['required', 'maxStringLength:30']}
                        errorMessages={['Required', 'The maximum allowed length is 30']}
                        autoFocus = {true}
                      />
                    </div>

                    <div className = {classes.textField}>
                      < MUTextValidator
                        id="lastname"
                        onChange={(e) => this.handleChange(e, 'lastname')}
                        name="lastname"
                        value={lastname}
                        placeholder = 'YOUR LASTNAME'
                        validators={['required', 'maxStringLength:30' ]}
                        errorMessages={['Required', 'The maximum allowed length is 30']}
                      />
                    </div>
                    <div className = {classes.textField}>
                      < MUTextValidator
                        id="emailaddress"
                        onChange={(e) => this.handleChange(e, 'emailaddress')}
                        name="emailaddress"
                        value={emailaddress}
                        placeholder = 'YOUR EMAIL'
                        validators={['required', 'isEmail', 'maxStringLength:50']}
                        errorMessages={['Required', 'Please enter a valid email address.', 'The maximum allowed length is 50']}
                      />
                    </div>

                    <div className = {classes.textField}>
                      < MUTextValidator
                        id="password"
                        onChange={(e) => this.handleChange(e, 'password')}
                        // endAdornmentIcon = {<RemoveRedEye onClick={(e) => this.togglePasswordMask(e, 'password')}/>}
                        name="password"
                        value={password}
                        placeholder = 'YOUR PASSWORD'
                        type={passwordIsMasked ? 'password' : 'text'}
                        validators={['required', 'minLength', 'strongPassword', 'notCommonPassword']}
                        errorMessages={[
                          'Required', 
                          'At least 8 characters must be entered.', 
                          'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
                          'This password is too common, please choose a stronger password.'
                        ]}
                      />
                    </div>

                    <div className = {classes.textField}>
                      <MUTextValidator
                        id="confirmpassword"
                        onChange={(e) => this.handleChange(e, 'confirmpassword')}
                        // endAdornmentIcon = {<RemoveRedEye onClick={(e) => this.togglePasswordMask(e, 'confirmpassword')}/>}
                        name="confirmpassword"
                        value={confirmpassword}
                        placeholder = 'RE-ENTER PASSWORD'
                        type={confirmPasswordIsMasked ? 'password' : 'text'}
                        validators={['required', 'isPasswordMatch']}
                        errorMessages={['Required', 'Password does not match']}
                      />
                    </div>

                    <div className = {classes.textField}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        className = {classes.formControl}
                        value={country}
                        onChange={(e) => this.handleChange(e, 'country')}
                        SelectProps={{
                          id: 'select',
                          className: classes.select,
                          inputProps: {
                            id: 'age-simple',
                            className: classes.selectbox,
                            placeholder: 'COUNTRY'
                          },
                          MenuProps: {
                            className: classes.menu
                          },
                          disableUnderline: true
                        }}
                        margin="normal"
                      >
                        <MenuItem value='-1'>
                          COUNTRY
                        </MenuItem>
                        {constants.countryData.map(option => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    <div className = {classes.textField}>
                      { this.state.captchaRendered ?
                        <MURecaptcha
                          verifyCallback={(response) => this.verifyCallback(response)}
                          sitekey = {RECAPTCHA_SITEKEY}
                        /> : null
                      }
                    </div>
                    <div className={classNames(classes.center, classes.btnSubmit)}>
                      <Button
                        variant="outlined"
                        color='primary'
                        className='outlinedPrimary'
                        type="submit"
                        text="REGISTER NOW"
                        disabled= {onServiceCall}
                      />
                    </div>
                  </ValidatorForm>
                </div>

              </div>
            }
          /> :

          <NotificationPage
            title='your registration has been received.'
            description={<h3 style={{textAlign: 'center', marginTop: '30px'}}>
            Click on the link sent at <span>{emailaddress}</span> to activate your account.</h3>}
          />
        }
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Register);
