/** *********** PRODUCT actions *****************/
export const SWITCH_DOCS_MAIN_CONTENT_TO_INFO = 'SWITCH_DOCS_MAIN_CONTENT_TO_INFO';
export const SWITCH_DOCS_MAIN_CONTENT_TO_SWAGGER = 'SWITCH_DOCS_MAIN_CONTENT_TO_SWAGGER';
export const SET_DOCS_SWAGGER_API = 'SET_DOCS_SWAGGER_API';
export const SET_DOCS_API_INFO = 'SET_DOCS_API_INFO';
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT_SERVICES = 'ADD_PRODUCT_SERVICES';
export const GET_PRODUCT_SERVICES = 'GET_PRODUCT_SERVICES';
export const ADD_PRODUCT_GROUPS = 'ADD_PRODUCT_GROUPS';
export const GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS';


/** ****** LOGIN actions ******/
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const TOKEN_REFRESH = 'TOKEN_REFRESH';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_ERROR = 'TOKEN_REFRESH_ERROR';
export const SET_USER_DISABLED = 'SET_USER_DISABLED';
export const TIMEOUT_CREATE = 'TIMEOUT_CREATE';

export const SET_ERROR = 'SET_ERROR';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const INITIALIZE = 'SITE_INITIALIZATION';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
