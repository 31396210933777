import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import {Drawer, AppBar, Toolbar, Hidden, Divider} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import InfoSection from './Sections/Info';
import ProductTab from './Sections/ProductTab';
// import {MUTypography, MUIconButton} from 'basic-components';
import MUTypography from '../../ui/MUTypography';
import MUIconButton from '../../ui/MUIconButton';

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  filterByType(type, filterType){
    if(filterType === "API"){
      return type === null || type === filterType;
    }
    else if(filterType === 'SERVICE'){
      return type === filterType;
    }
    return false;
  }

  renderSidebarComponents() {
    const baasList =  this.props.combinedProductAndServices.filter(item => this.filterByType(item.type, "SERVICE"))
    const apiList =  this.props.combinedProductAndServices.filter(item => this.filterByType(item.type, "API"))
    console.log("Baas List:", baasList); // Burada ekrana yazdırıyoruz
    console.log("Api List:", apiList); // Burada ekrana yazdırıyoruz

    



    return (
      <div>
        <InfoSection {...this.props}/>
        <Divider />
        <ProductTab {...this.props} id={"1"} name={"API"} list ={apiList}/>
        <Divider />
        <ProductTab {...this.props} id={"2"} name={"BAAS"} list ={baasList}/>
      </div>
    );
  }

  render() {
    const {classes, theme} = this.props;
    const { mobileOpen} = this.state;

    return (
      <div className = {classes.sideBar}>
        <Hidden mdUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <MUIconButton
                color="inherit"
                ariaLabel="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.navIconHide}
              >
                <Menu />
              </MUIconButton>
              <MUTypography variant="title" color="inherit" noWrap = {true}>sss </MUTypography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true 
            }}
          >
            {this.renderSidebarComponents()}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {this.renderSidebarComponents()}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);

