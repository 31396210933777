import React from 'react';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Apps, AddBox} from '@material-ui/icons';
import View from '../View';
import Applications from '../MyApplications/Applications';
import NewApplication from '../NewApplication';
import MUGrid from '../ui/MUGrid';
import Analytics from '../Analytics';
import Header from '../Header';
import BasicPageComponent from '../BasicPageComponent';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditProfile from '../Profile';
import DashboardHome from './DashboardHome.js';
import MyTicket from '../Ticket/MyTicket'
import CreateTicket from '../Ticket/CreateTicket'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      home: false,
      applications: false,
      analytics: false,
      newapplication: false,
      editapplication: false,
      profile: false,
      myTickets: false,
      createTicket: false,
      activePage: '',
      pageTitle: '',
      pageDescription: '',
      supportMenuOpen: false,
    };

    this.state = this.initialState;
  }
  

  componentDidMount() {
    this.updateStateFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.updateStateFromQuery();
    }
  }

  updateStateFromQuery() {
    const query = queryString.parse(this.props.location.search);
    this.setState({
      home: query.page === 'home',
      applications: query.page === 'applications',
      analytics: query.page === 'analytics',
      newapplication: query.page === 'newapplication',
      editapplication: query.page === 'editapplication',
      profile: query.page === 'profile',
      myTickets: query.page === 'myTickets',
      createTicket: query.page === 'createTicket',
    });
  }

  handleMenuChange(page, { title, description }) {
    const query = queryString.stringify({ page });
    this.props.history.push(`?${query}`);
    this.setState({
      pageTitle: title,
      pageDescription: description,
    });
  }

  toggleSupportMenu = () => {
    this.setState((prevState) => ({
      supportMenuOpen: !prevState.supportMenuOpen,
    }));
  };

  render() {
    const { classes } = this.props;
    const { editapplication, newapplication, supportMenuOpen, activeComponent } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header />
        <BasicPageComponent
  title={this.state.pageTitle}
  description={this.state.pageDescription}
          children={
            <View className="container">
              <MUGrid container spacing={24}>
                <MUGrid item xs={3}>
                  <MenuList className={classes.menuList}>
                    <MenuItem
                      classes={{ selected: classes.selected }}
                      selected={this.state.home}
                      onClick={(e) => this.handleMenuChange('home', {
                        title: '',
                        description: '',
                      })}
                    >
                      <ListItemIcon className={classes.icon} style={{ minWidth: '40px', display: 'flex', justifyContent: 'center' }}>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.primary }}
                        inset
                        primary="Dashboard Home"
                      />
                    </MenuItem>
                    <MenuItem
                      classes={{ selected: classes.selected }}
                      selected={newapplication}
                      onClick={(e) => this.handleMenuChange('newapplication', {
                        title: 'New Application',
                        description: 'Create a new application from this page.',
                      })}
                    >
                      <ListItemIcon className={classes.icon} style={{ minWidth: '40px', display: 'flex', justifyContent: 'center' }}>
                        <AddBox />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.primary }}
                        inset
                        primary={'New Application'}
                      />
                    </MenuItem>

                    <MenuItem
                      classes={{ selected: classes.selected }}
                      onClick={(e) => this.handleMenuChange('applications', {
                        title: 'My Applications',
                        description: 'Via My Application page, you can create new applications or you can edit existing applications.',
                      })}
                      selected={this.state.applications}
                    >
                      <ListItemIcon className={classes.icon} style={{ minWidth: '40px', display: 'flex', justifyContent: 'center' }}>
                        <Apps />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.primary }}
                        inset
                        primary="My Applications"
                      />
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={this.toggleSupportMenu}
                    >
                      <ListItemIcon className={classes.icon} style={{ minWidth: '40px', display: 'flex', justifyContent: 'center' }}>
                        <SupportAgentIcon />
                      </ListItemIcon>
                      <ListItemText 
                      classes={{ primary: classes.primary }} 
                      primary="Support Ticket" />
                      {supportMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </MenuItem>
                    {supportMenuOpen && (
                      <div style={{ paddingLeft: '20px' }}>
                        <MenuItem
                        className={classes.menuItem}
                        onClick={() => this.handleMenuChange('createTicket', {
                          title: 'Create a Ticket',
                          description: 'In this section, you can create a support ticket and track its progress.',
                        })}>
                          <ListItemText classes={{ primary: classes.primary }} primary="Create a Ticket" />
                        </MenuItem>
                        <MenuItem
                        className={classes.menuItem}
                        onClick={() => this.handleMenuChange('myTickets', {
                          title: 'My Tickets',
                          description: 'You can check and edit your tickets from here and add replies to your answered tickets.',
                        })}>
                            <ListItemText classes={{ primary: classes.primary }} primary="My Tickets" />
                            </MenuItem>
                      </div>
                    )}

                    <MenuItem
                      className={`${classes.menuItem} ${
                        activeComponent === 'profile' ? classes.selected : ''
                      }`}
                      onClick={() => this.handleMenuChange('profile', {
                        title: 'Edit Profile',
                        description: 'Via Edit Profile page, you can edit your information quickly or complete your missing information.',
                      })}
                    >
                      <ListItemIcon className={classes.icon} style={{ minWidth: '40px', display: 'flex', justifyContent: 'center' }}>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.primary }}
                        primary="Edit Your Profile"
                      />
                    </MenuItem>
                  </MenuList>
                </MUGrid>
                <MUGrid item xs={9}>
  {/* Home sayfasını render et */}
  {this.state.home && <DashboardHome />}

  {/* Applications sayfasını render et */}
  {this.state.applications && (
    <Applications
      open={this.state.applications}
      {...this.props}
      onCardClick={this.onCardClick}
      onAddClick={(e) => this.handleMenuChange('newapplication', {
        title: 'New Application',
        description: 'Create a new application from this page.',
      })}
    />
  )}

  {/* NewApplication bileşenini render et */}
  {(editapplication || newapplication) && (
    <NewApplication
      open={editapplication || newapplication}
      editapplication={editapplication}
      newapplication={newapplication}
      {...this.props}
      {...this.state}
    />
  )}

  {/* Analytics sayfasını render et */}
  {this.state.analytics && <Analytics />}

  {/* Profile sayfasını render et */}
  {this.state.profile && <EditProfile />}

  {/* MyTickets sayfasını render et */}
  {this.state.myTickets && <MyTicket />}

  {/* CreateTicket sayfasını render et */}
  {this.state.createTicket && <CreateTicket />}
</MUGrid>
              </MUGrid>
            </View>
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(Dashboard);
