import React from 'react';

const DashboardHome = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start', // Yukarı hizalama
        justifyContent: 'flex-start', // SOLA YASLAMA
        height: '100%',
        backgroundColor: '#F8F9FA',
      }}
    >
      {/* Sol tarafta PNG */}
      <div style={{ textAlign: 'left' }}> {/* Sol hizalama */}
        <img
          src="static/images/dashboard.jpg"
          alt="Dashboard Animation"
          style={{
            width: '900px', 
            maxWidth: '100%',
            height: 'auto',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
