import React from 'react';
import PropTypes from 'prop-types';
// import {MUCircularProgress} from 'basic-components';
import MUCircularProgress from '../ui/MUCircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from './style';
import Sidebar from './Containers/Sidebar';
import MainContent from './Containers/MainContent';
import { getProducts, getProductServices, getProductGroups } from '../../redux/actions';
import BasicPageComponent from '../BasicPageComponent';
import Header from '../Header';

class Docs extends React.Component {

  componentDidMount() {
    this.props.getProducts();
    this.props.getProductServices();
    this.props.getProductGroups();

    localStorage.removeItem('baasDocs')
    localStorage.removeItem('apiDocs')

    window.scrollTo(0, 0);
  }

  render() {
    const { classes, products } = this.props;
    return (

      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <BasicPageComponent
          title='Documentation'
          description='We have developed a detailed documentation to
          improve the Albaraka API experience.
          You can access all the information you need via Albaraka API documentation.'
          children={(!_.isEmpty(products.productList) && !_.isEmpty(products.services)) ? (
            <div className={classes.root}>
              <Sidebar {...this.props} handleApiButtonClick={this.handleApiButtonClick} />
              <MainContent {...this.props} />
            </div>
          ) :
            <div style={{ minHeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MUCircularProgress className={classes.progress} size={50} />
            </div>
          }
        />
      </div>
    );
  }
}

Docs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(state => ({ products: state.products }), { getProducts, getProductServices, getProductGroups })
)(Docs);

