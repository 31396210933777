import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import * as apiDashBoardService from "../../../services/apidashboardservice.js";

const MyTicket = () => {
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedTicketDetails, setSelectedTicketDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [yourReply, setYourReply] = useState("");

  useEffect(() => {
    const fetchTickets = async () => {
      apiDashBoardService
        .getTicket(null)
        .then((response) => {
          const tickets = response.data.tickets || [];
          setTickets(tickets);
        });
    };

    fetchTickets();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

 const convertErrorTypeToString = (errorType) => {
    switch (errorType) {
      case 1:
        return 'An API';
      case 2:
        return 'The Sandbox';
      case 3:
        return 'Production';
      case 4:
        return 'Other';
      default:
        return 'Unknown Type';
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = async (ticketNumber) => {
    try {
      const response = await apiDashBoardService.getTicketDetail(ticketNumber);
      const ticketDetails = response.data || {};
      setSelectedTicketDetails(ticketDetails);
      setDescription(ticketDetails.description || "");
      setYourReply("");
      setOpen(true);
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTicketDetails(null);
    setDescription("");
    setYourReply("");
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleYourReplyChange = (event) => {
    setYourReply(event.target.value);
  };

  const handleSave = async () => {
    try {
      if (selectedTicketDetails && selectedTicketDetails.replies && selectedTicketDetails.replies.length === 0) {
        await apiDashBoardService.updateTicket(selectedTicketDetails.ticketNumber, description);
      } else if (selectedTicketDetails && selectedTicketDetails.replies && selectedTicketDetails.replies.length > 0) {
        await apiDashBoardService.replyCreate(selectedTicketDetails.ticketNumber, yourReply);
      }
      setOpen(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
        <TableHead>
  <TableRow>
    <TableCell style={{
      fontSize: "13px", fontWeight: "bold", color: "#222",
      padding: "16px", fontFamily: "'Roboto', sans-serif",
      textAlign: "center" 
    }}>
      Ticket Number
    </TableCell>
    <TableCell style={{
      fontSize: "13px", fontWeight: "bold", color: "#222",
      padding: "16px", fontFamily: "'Roboto', sans-serif",
      textAlign: "center" 
    }}>
      Status
    </TableCell>
    <TableCell style={{
      fontSize: "13px", fontWeight: "bold", color: "#222",
      padding: "16px", fontFamily: "'Roboto', sans-serif",
      textAlign: "center" 
    }}>
      Creation Date
    </TableCell>
    <TableCell style={{
      fontSize: "13px", fontWeight: "bold", color: "#222",
      padding: "16px", fontFamily: "'Roboto', sans-serif",
      textAlign: "center" 
    }}>
      Error Type
    </TableCell>
    <TableCell style={{
      fontSize: "13px", fontWeight: "bold", color: "#222",
      padding: "16px", fontFamily: "'Roboto', sans-serif",
      textAlign: "center" 
    }}>
      Subject
    </TableCell>
  </TableRow>
</TableHead>

          <TableBody>
  {tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((ticket) => (
      <TableRow
        key={ticket.ticketNumber}
        onClick={() => handleRowClick(ticket.ticketNumber)}
        style={{ cursor: "pointer" }}
      >
        <TableCell style={{ fontSize: "11px", fontWeight: "500", padding: "12px", textAlign: "center" }}>
          {ticket.ticketNumber}
        </TableCell>
        <TableCell style={{ fontSize: "11px", fontWeight: "500", padding: "12px", textAlign: "center" }}>
          {ticket.status}
        </TableCell>
        <TableCell style={{ fontSize: "11px", fontWeight: "500", padding: "12px", textAlign: "center" }}>
          {ticket.creationDate}
        </TableCell>
        <TableCell style={{ fontSize: "11px", fontWeight: "500", padding: "12px", textAlign: "center" }}>
          {convertErrorTypeToString(ticket.errorType)}
        </TableCell>
        <TableCell style={{ fontSize: "11px", fontWeight: "500", padding: "12px", textAlign: "center" }}>
          {ticket.subject}
        </TableCell>
      </TableRow>
    ))}
</TableBody>

        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tickets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Ticket Details</DialogTitle>
        <DialogContent>
          {selectedTicketDetails && (
            <>
              <TextField
                label="Error Type"
                value={convertErrorTypeToString(selectedTicketDetails.errorType) || ""}
                fullWidth
                margin="dense"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Selected API"
                value={selectedTicketDetails.apiName || ""}
                fullWidth
                margin="dense"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Endpoint"
                value={selectedTicketDetails.endpointAddress || ""}
                fullWidth
                margin="dense"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Subject"
                value={selectedTicketDetails.subject || ""}
                fullWidth
                margin="dense"
                InputProps={{ readOnly: true }}
              />
              <TextField
                label="Description"
                value={description}
                fullWidth
                margin="dense"
                InputProps={{
                  readOnly: !(
                    selectedTicketDetails &&
                    selectedTicketDetails.replies &&
                    selectedTicketDetails.replies.length === 0
                  ),
                  style: !(
                    selectedTicketDetails &&
                    selectedTicketDetails.replies &&
                    selectedTicketDetails.replies.length === 0
                  )
                    ? { backgroundColor: "#f5f5f5", border: "1px solid #ccc" } 
                    : { backgroundColor: "#e8f5e9", border: "2px solid #4caf50" }, 
                }}
                multiline
                rows={3}
                onChange={handleDescriptionChange}
              />
              {selectedTicketDetails.replies && selectedTicketDetails.replies.length > 0 && (
                <>
                  <Typography variant="h6" style={{ marginTop: "20px" }}>
                    Reply
                  </Typography>
                  {selectedTicketDetails.replies
                  .slice() 
                  .reverse()
                  .map((reply, index) => (
                  <TextField
                  key={index}
                  label={`${reply.userRole === "D" ? "Developer" : "Admin"} Reply`}
                  value={reply.description}
                  fullWidth
                  margin="dense"
                  InputProps={{ readOnly: true }}
                  multiline
                  />
                  ))}

                  {selectedTicketDetails && selectedTicketDetails.status !== 'C' && (
                    <>
                      <Typography variant="h6" style={{ marginTop: "20px" }}>
                        Your Reply
                      </Typography>
                      <TextField
                        label="Your Reply"
                        value={yourReply}
                        fullWidth
                        margin="dense"
                        InputProps={{
                          readOnly: !(
                            selectedTicketDetails &&
                            selectedTicketDetails.replies &&
                            selectedTicketDetails.replies.length > 0
                          ),
                          style: !(
                            selectedTicketDetails &&
                            selectedTicketDetails.replies &&
                            selectedTicketDetails.replies.length === 0
                          ) && { backgroundColor: "#e8f5e9", border: "2px solid #4caf50" },
                        }}
                        multiline
                        rows={3}
                        onChange={handleYourReplyChange}
                      />
                    </>
                  )}
                </>
              )}
              <TextField
                label="E-Mail Address"
                value={selectedTicketDetails.userEmail || ""}
                fullWidth
                margin="dense"
                InputProps={{ readOnly: true }}
                style={{ marginTop: "20px" }}
              />
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default MyTicket;
